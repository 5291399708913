import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import EcommerceReducer from './apps/eCommerce/EcommerceSlice';
import BlogReducer from './apps/blog/BlogSlice';


export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    ecommerceReducer: EcommerceReducer,
    blogReducer: BlogReducer,
  },
});

export default store;
