import React from 'react';
import { Box, Container, Typography, Grid, Stack, styled, Button } from '@mui/material';
import BenefitsTitle from './BenefitsTitle';
import AnimationFadeIn from '../animation/Animation';
import { NavLink } from 'react-router-dom';
// images
import icon1Img from 'src/assets/images/landingpage/shape/imgicon-31.webp';
import icon2Img from 'src/assets/images/landingpage/shape/imgicon-30.webp';
import icon3Img from 'src/assets/images/landingpage/shape/imgicon-29.webp';
import icon4Img from 'src/assets/images/landingpage/shape/imgicon-28.webp';
import icon5Img from 'src/assets/images/landingpage/shape/imgicon-27.webp';


const StyledButton = styled(Button)(() => ({
  padding: '13px 48px',
  fontSize: '16px',
}));

const StyledButton2 = styled(Button)(({ theme }) => ({
  padding: '13px 48px',
  fontSize: '16px',
}));


const benefitsData = [
  {
    icon: icon1Img,
    title: 'Australian made',
    subtext: '',
  },
  {
    icon: icon2Img,
    title: 'Nationwide delivery',
    subtext: '',
  },
  {
    icon: icon3Img,
    title: 'Quick turnaround',
    subtext: '',
  },
  {
    icon: icon4Img,
    title: 'High-quality finishes',
    subtext: '',
  },
  {
    icon: icon5Img,
    title: 'Innovative products',
    subtext: '',
  },
];



const BenefitsNoSlider = () => {
  return (
    <Box
      id="section-benefits-noslider"
      bgcolor="action.hover"
      sx={{
        py: {
          xs: '70px',
          lg: '120px',
        },
      }}
    >
      <Container maxWidth="xl">
        <BenefitsTitle />

        <Box mt={6}>
          <Grid container spacing={6} justifyContent="center">
            {benefitsData.map((benefits, index) => (
              <Grid item xs={12} sm={4} lg={3} xl={2} mb={3} textAlign="center" key={index}>
                <AnimationFadeIn>
                  <Box mt={3} color="primary.main">
                    <img src={benefits.icon} alt={benefits.title} />
                  </Box>
                  <Typography variant="h5" mt={3}>
                    {benefits.title}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" mt={1} mb={3}>
                    {benefits.subtext}
                  </Typography>
                </AnimationFadeIn>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={6} id="button-wrapper-about01">
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={4} ml={3}>
            <StyledButton2 variant="contained" component={NavLink} to="/Get-quote">
              Get a Quote
            </StyledButton2>
            <StyledButton variant="outlined" color="primary" component={NavLink} to="/Capabilities">
              Our Capabilities
            </StyledButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default BenefitsNoSlider;
