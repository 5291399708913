import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography, Stack, CardContent } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
// components
import Footer from '../../landingpage/footer/Footer';
import CapabilitiesServicesGrid from './CapabilitiesServicesGrid';
import CapabilitiesCards from './CapabilitiesCards';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/Capabilities',
    title: 'Capabilities',
  },
];


const Capabilities = () => {
  return (
    <PageContainer
      title="How we help – QSP Queensland Screenprints"
      description="No matter the industry, we have a solution. We do not pretend to be “all things to all people” or the “best at everything”. Our goal is to develop long term, mutually beneficial partnerships with companies that require our core product and service offerings. We realise that every business is different and therefore require unique […]"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[-4, -8]}>
          <Grid item xs={12} lg={12}>
            <AnimationFadeIn>
              <Typography
                variant="h1"
                color="#756EFF"
                fontSize={56}
                lineHeight={1.2}
                fontWeight={900}
                mt={2}
              >
                Endless possibilities.
              </Typography>
            </AnimationFadeIn>
            <Box pt={4} pb={0} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h2" color="grey.400" fontWeight={700} lineHeight={1.2}>
                  Artwork design & setup
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={1} lineHeight={1.8}>
                  Our goal is to develop long term, mutually beneficial partnerships with companies
                  that require our core product and service offerings. We realise that every
                  business is different and therefore require unique needs when it comes to
                  printing. Our Business Development Managers and Concept Consultants work closely
                  with our clients to understand the dynamics of their business and develop the
                  right print solution to suit their needs.
                </Typography>
              </AnimationFadeIn>
            </Box>
          </Grid>
        </Grid>
        <AnimationFadeIn>
          <CapabilitiesServicesGrid />
        </AnimationFadeIn>
        <AnimationFadeIn>
          <Grid container spacing={3} mt={8}>
            <Grid item xs={12} lg={12}>
              <Typography
                id="capabilitiesCardTitle"
                variant="h1"
                color="#1CEBC2"
                fontSize={40}
                component={'div'}
                lineHeight={1.2}
                fontWeight={900}
                mb={4}
              >
                Expert Print Management
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }} id="capabilitiesCardContent">
              <Typography variant="h2" color="grey.400" fontWeight={700} lineHeight={1.2}>
                Packaging & Distribution
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                component="div"
                fontWeight={200}
                mt={1}
                lineHeight={1.8}
              >
                Handled in-house by our skilled team of finishing personnel. Our partner
                couriers/freight-lines ensure your job arrives in perfect condition every time.
              </Typography>
              <Typography variant="h2" mt={2} color="grey.400" fontWeight={700} lineHeight={1.8}>
                Installation
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                component="div"
                fontWeight={200}
                mt={1}
                lineHeight={1.8}
              >
                Installation can be arranged Australia-wide.
              </Typography>
              <Typography variant="h2" mt={2} color="grey.400" fontWeight={700} lineHeight={1.8}>
                Print Management
              </Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                component="div"
                fontWeight={200}
                mt={1}
                lineHeight={1.8}
              >
                Regardless of the size or scale of the job, we are equipped with the expertise,
                technology and capacity to deliver. With our comprehensive range of print
                technologies, we are able to manage your entire promotional campaign, ensuring
                quality consistency across the board. QSP in-house facilities are capable of
                operating independently in order to successfully maintain full control of customer
                service, pre-press, printing, finishing, delivery and installation Australia-wide.
                Our fully implemented internal Quality Assurance system confirms our commitment to
                providing our clients with a high quality product. We also believe in blending the
                new with the ‘traditional’ which is why our factories contain both screen and large
                format digital printers.
              </Typography>
            </CardContent>
            <Stack direction="row" spacing={2} px={2} pb={3}></Stack>
          </Box>
        </AnimationFadeIn>
        <AnimationFadeIn>
          <Grid container spacing={3} mt={8}>
            <Grid item xs={12} lg={12}>
              <Typography
                id="capabilitiesCardTitle"
                variant="h1"
                color="#1CEBC2"
                fontSize={40}
                component={'div'}
                lineHeight={1.2}
                fontWeight={900}
                mb={1}
              >
                Fully Equipped
              </Typography>
            </Grid>
          </Grid>
        </AnimationFadeIn>
        <CapabilitiesCards />
      </Container>
      <Footer />
    </PageContainer>
  );
};

export default Capabilities;
console.clear();
