import React, { useState } from 'react';
import { Button, Box, Collapse, Tooltip, Stack, IconButton, Toolbar, styled } from '@mui/material';
import {
  IconChevronDown,
  IconPhone,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
} from '@tabler/icons';
import LogoLight from 'src/assets/images/logos/light-logo.webp';




const MobileSidebar = () => {
  const [toggle, setToggle] = useState(false);
  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: '16px',
    color: theme.palette.text.light,
    borderBottom: '2px solid transparent',
    '&:hover': {
      //backgroundColor: '#213052',
      color: 'white',
    },
  }));

  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Box p={3} sx={{ marginLeft:'-2rem'}}>
        <Stack direction="column" spacing={2}>
          <Button
            sx={{
              transition: 'all 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            variant="outlined"
            color="primary"
            href="#"
            fontWeight="500"
            startIcon={<IconPhone width={18} />}
          >
            (07) 3277 3566
          </Button>
          <Box width="8px" />
          <Button
            color="inherit"
            href="/"
            sx={{
              justifyContent: 'start',
            }}
          >
            Home
          </Button>
          <Button
            color="inherit"
            href="/About-us"
            sx={{
              justifyContent: 'start',
            }}
          >
            About Us
          </Button>
          <Button
            color="inherit"
            href="/Products"
            sx={{
              justifyContent: 'start',
            }}
          >
            Products
          </Button>
          <Button
            color="inherit"
            href="/How-we-help"
            sx={{
              justifyContent: 'start',
            }}
          >
            How We Help
          </Button>
          <Button
            color="inherit"
            href="/Capabilities"
            sx={{
              justifyContent: 'start',
            }}
          >
            Capabilities
          </Button>
          {/* <Button
            color="inherit"
            href="/Our-works"
            sx={{
              justifyContent: 'start',
            }}
          >
            Our Works
          </Button> */}
          <Button
            color="inherit"
            onClick={() => setToggle(!toggle)}
            endIcon={<IconChevronDown width={20} />}
            sx={{
              justifyContent: 'space-between',
            }}
          >
            Learn
          </Button>
          {toggle && (
            <Collapse in={toggle}>
              <Box m="-21px">
                <Box ml={1}>
                  <Stack
                    direction="column"
                    spacing={1}
                    margin={2}
                    padding={1}
                    pb={2}
                    sx={{ background: '#fff', borderRadius: '8px' }}
                  >
                    {/* <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        justifyContent: 'start',
                        marginRight: '2rem',
                        marginTop: '1rem',
                        fontSize: '0.875rem',
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#e5eaef',
                          color: '#2A3547',
                        },
                      }}
                      href="/Learn/News"
                    >
                      News
                    </StyledButton> */}
                    <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        justifyContent: 'start',
                        fontSize: '0.875rem',
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#e5eaef',
                          color: '#2A3547',
                        },
                      }}
                      href="/Learn/Frequently-asked-questions"
                    >
                      FAQs
                    </StyledButton>
                    <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        justifyContent: 'start',
                        marginLeft: '2rem',
                        marginRight: '2rem',
                        marginTop: '1rem',
                        fontSize: '0.875rem',
                        color: '#2A3547',
                        '&:hover': {
                          bgcolor: '#e5eaef',
                          color: '#2A3547',
                        },
                      }}
                      href="/Learn/Artwork-requirements/"
                    >
                      Artwork Requirements
                    </StyledButton>
                  </Stack>
                </Box>
              </Box>
            </Collapse>
          )}
          <Button
            color="inherit"
            href="/Contact-us"
            sx={{
              justifyContent: 'start',
            }}
          >
            Contact Us
          </Button>
          <Button color="primary" variant="contained" href="/Get-quote">
            Get Quote
          </Button>
          <ToolbarStyled>
            <Tooltip title="linkedin">
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="linkedin"
              >
                <IconBrandLinkedin width={32} color="#5A6A85" />
              </IconButton>
            </Tooltip>
            <Tooltip title="facebook">
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="facebook"
              >
                <IconBrandFacebook width={32} color="#5A6A85" />
              </IconButton>
            </Tooltip>
            <Tooltip title="instagram">
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="instagram"
              >
                <IconBrandInstagram width={32} color="#5A6A85" />
              </IconButton>
            </Tooltip>
          </ToolbarStyled>
          <ToolbarStyled>
         <Stack>
          <img src={LogoLight} alt="Logo Light" width="100%" style={{ opacity:"0.5" }} id="mainLogo" />
         </Stack>
          </ToolbarStyled>
        </Stack>
      </Box>
    </>
  );
};

export default MobileSidebar;
