import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import QuoteFormWizard from './QuoteFormWizard';
import Breadcrumb from '../../../layouts/full/shared/breadcrumb/Breadcrumb';
// components
//import LpHeader from '../../../components/landingpage/header/Header';
import ContactFooter from '../../landingpage/footer/ContactFooter';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    title: 'Get quote',
  },
];

const GetQuote = () => {
  return (
    <PageContainer
      title="Get quote - QSP Queensland Screenprints – Real Estate Signs, Builders Signs, Point of Sale Signs, Australia"
      description="QSP is your one stop printing shop; from Banners and Builders Signs to Real Estate Signs, we've got you covered!"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Box mt={18} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[-24, -22]}>
          <Grid item xs={12} lg={12}>
            <AnimationFadeIn>
              <Typography
                variant="h1"
                color="#1CEBC2"
                fontSize={32}
                lineHeight={1.2}
                fontWeight={900}
                mt={4}
                ml={0}
              >
                Queensland Screenprints are an Australian company.
              </Typography>
            </AnimationFadeIn>
            <Box pt={1} pb={3} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={300} ml={0}>
                  Please complete the form below and we’ll be in contact with you shortly.
                </Typography>
              </AnimationFadeIn>
            </Box>
            <QuoteFormWizard />
          </Grid>
          <Grid item xs={12} lg={6} mb={8} mt={8}></Grid>
        </Grid>
      </Container>

      <ContactFooter />
    </PageContainer>
  );
};

export default GetQuote;
