import React from 'react';
import { Stack, Typography, styled, Button } from '@mui/material';
import ChildCard from 'src/components/shared/ChildCard';
import { IconPhone, IconMail, IconMapPin } from '@tabler/icons';
import LogoDark from 'src/assets/images/logos/dark-logo.webp';


const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '500',
  color: theme.palette.text.light,
  '&:hover': {
    backgroundColor: '#1C2945',
    color: '#1CEAC1',
  },
}));

const BusinessCard01 = () => (
  <ChildCard>
    <Typography fontWeight={600} variant="h4" mb={1} mt={-3}>
      <img src={LogoDark} alt="Logo Dark" height="64px" />
    </Typography>
    <Typography color="primary.light" fontSize={13} variant="subtitle2" mb={4}>
      Proudly part of Queensland Nameplates Group Pty Ltd.
    </Typography>
    <Stack direction="row" gap={2} alignItems="center" mb={3} sx={{}}>
      <StyledButton
        color="inherit"
        variant="text"
        href="mailto:sales@qspgroup.com.au"
        rel="noopener noreferrer"
      >
        <IconMail size="21" color="#E8F7FF" />
        <Typography
          color="primary.light"
          variant="h6"
          ml={2}
          mb={0.25}
          fontWeight={400}
          sx={{
            transition: 'all 0.25s',
            '&:hover': {
              color: '#1CEAC1',
              transition: 'all 0.25s',
            },
          }}
        >
          sales@qspgroup.com.au
        </Typography>
      </StyledButton>
    </Stack>
    <Stack direction="row" gap={2} alignItems="center" mb={3}>
      <StyledButton
        color="inherit"
        variant="text"
        href="tel:+61732772566"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconPhone size="21" color="#E8F7FF" />
        <Typography
          color="primary.light"
          variant="h6"
          ml={2}
          mb={0.25}
          fontWeight={400}
          sx={{
            transition: 'all 0.25s',
            '&:hover': {
              color: '#1CEAC1',
              transition: 'all 0.25s',
            },
          }}
        >
          (07) 3277 2566
        </Typography>
      </StyledButton>
    </Stack>
    <Stack direction="row" gap={2} alignItems="center" mb={1}>
      <StyledButton
        color="inherit"
        variant="text"
        href="https://www.google.com/maps/search/?api=1&query=61+Pentex+Street%2C+Salisbury+QLD+4107"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconMapPin size="21" color="#E8F7FF" />
        <Typography
          color="primary.light"
          variant="h6"
          ml={2}
          mb={0.25}
          fontWeight={400}
          sx={{
            transition: 'all 0.25s',
            '&:hover': {
              color: '#1CEAC1',
              transition: 'all 0.25s',
            },
          }}
        >
          61 Pentex Street, Salisbury QLD 4107
        </Typography>
      </StyledButton>
    </Stack>
  </ChildCard>
);

export default BusinessCard01;
