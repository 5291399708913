import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoDark from 'src/assets/images/logos/dark-logo.webp';
import LogoDarkRTL from 'src/assets/images/logos/dark-rtl-logo.webp';
import LogoLight from 'src/assets/images/logos/light-logo.webp';
import LogoLightRTL from 'src/assets/images/logos/light-logo-rtl.webp';
import { styled } from '@mui/material';

const Logo = () => {
  const customizer = useSelector((state) => state.customizer);
  const LinkStyled = styled(Link)(() => ({
    height: customizer.TopbarHeight,
    width: customizer.isCollapse ? '40px' : '320px',
    overflow: 'hidden',
    display: 'block',
    scale: '0.9',
  }));

  if (customizer.activeDir === 'ltr') {
    return (
      <LinkStyled to="/" id="mainLogoWrapper">
        {customizer.activeMode === 'dark' ? (
          <img src={LogoLight} alt="Logo Light" height="56px" id="mainLogo" />
        ) : (
          <img src={LogoDark} alt="Logo Dark" height="56px" id="mainLogo" />
        )}
      </LinkStyled>
    );
      }
      return (
        <LinkStyled to="/">
          {customizer.activeMode === 'dark' ? (
            <img src={LogoDarkRTL} alt="Logo Dark RTL" height="56px" />
          ) : (
            <img src={LogoLightRTL} alt="Logo Light RTL" height="56px" />
          )}
        </LinkStyled>
      );
};

export default Logo;