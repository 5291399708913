import React, { useCallback, useEffect, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { NextButton, PrevButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { motion } from 'framer-motion';
import Image1 from 'src/assets/images/gallery/corflute.png';
import Image2 from 'src/assets/images/gallery/customsigns.png';
import Image3 from 'src/assets/images/gallery/largeformatprints.png';
import Image4 from 'src/assets/images/gallery/poinofsale.png';
import Image5 from 'src/assets/images/gallery/shopfront.png';
import Image6 from 'src/assets/images/gallery/vinylsigns.png';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { auto } from '@cloudinary/url-gen/actions/resize';


const TWEEN_FACTOR_BASE = 0.84;

const numberWithinRange = (number, min, max) => Math.min(Math.max(number, min), max);

const EmblaCarousel = () => {
  const options = {
    loop: true,
    align: 'center',
    skipSnaps: false,
    containScroll: 'trimSnaps',
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const tweenFactor = useRef(0);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  const setTweenFactor = useCallback((emblaApi) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
  }, []);

  const tweenOpacity = useCallback((emblaApi, eventName) => {
    const engine = emblaApi.internalEngine();
    const scrollProgress = emblaApi.scrollProgress();
    const slidesInView = emblaApi.slidesInView();
    const isScrollEvent = eventName === 'scroll';

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress;
      const slidesInSnap = engine.slideRegistry[snapIndex];

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target();

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target);

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress);
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress);
              }
            }
          });
        }

        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current);
        const opacity = numberWithinRange(tweenValue, 0, 1).toString();
        emblaApi.slideNodes()[slideIndex].style.opacity = opacity;
      });
    });
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    setTweenFactor(emblaApi);
    tweenOpacity(emblaApi);
    emblaApi
      .on('reInit', setTweenFactor)
      .on('reInit', tweenOpacity)
      .on('scroll', tweenOpacity)
      .on('slideFocus', tweenOpacity);
  }, [emblaApi, tweenOpacity]);

  const slideData = [
    {
      title: 'Large Format Printing',
      description: 'with digital printing.',
      imageUrl: Image3,
      linkUrl: '/Capabilities',
    },
    {
      title: 'Shop Front Signage',
      description: 'External facias, window graphics and light boxes',
      imageUrl: Image5,
      linkUrl: '/Products/External-signage',
    },
    {
      title: 'Point of Sale Signage',
      description: 'Posters, standee, product displays',
      imageUrl: Image4,
      linkUrl: '/Products/Point-of-sale-solutions',
    },
    {
      title: 'Corflute signage',
      description: 'Elections, advertising, real estate and builders signage',
      imageUrl: Image1,
      linkUrl: '/Products/Corflute-signage',
    },
    {
      title: 'Vinyl Signage',
      description: 'Stickers, window graphics, epoxy labels ',
      imageUrl: Image6,
      linkUrl: '/Products/Stickers',
    },
    {
      title: 'Custom Signs',
      description: 'Ask us about what we can do for you',
      imageUrl: Image2,
      linkUrl: '/Products/Custom-signs',
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
        ease: 'easeInOut',
        duration: 0.8,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { ease: 'easeInOut', duration: 0.6 },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1 },
    },
  };

  const viewportVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.6, duration: 1 },
    },
  };

  return (
    <div className="embla" style={{ overflow: 'hidden', marginBottom: '0' }}>
      <motion.div
        className="emble__row"
        initial="hidden"
        animate="visible"
        variants={titleVariants}
      >
        <h1
          className="embla__pagetitle"
          style={{
            textAlign: 'center',
            color: '#1d2945',
            margin: 'auto',
            width: '100%',
          }}
        >
          Our Works
        </h1>
      </motion.div>
      <motion.div
        className="embla__viewport"
        ref={emblaRef}
        initial="hidden"
        animate="visible"
        variants={viewportVariants}
      >
        <div className="embla__container">
          {slideData.map((slide, index) => (
            <div className="embla__slide" key={index}>
              <img className="embla__slide__img" src={slide.imageUrl} alt={`Slide ${index + 1}`} />
              <motion.div
                className="embla__slide__info"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                key={selectedIndex}
              >
                <motion.h2 variants={itemVariants}>{slide.title}</motion.h2>
                <motion.p variants={itemVariants}>{slide.description}</motion.p>
                <Link to={slide.linkUrl}>
                  <IconButton
                    color="primary"
                    aria-label="information"
                    style={{ position: 'relative', bottom: 10, right: 10 }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Link>
              </motion.div>
            </div>
          ))}
        </div>
      </motion.div>

      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : '',
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
