import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Divider, Box } from '@mui/material';
import { IconChevronDown } from '@tabler/icons';

const Questions = () => {
  return (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Typography variant="h3" textAlign="center" mb={1} color={'primary.green'}>
            Frequently asked questions
          </Typography>
          <Typography variant="h6" fontWeight={400} color="textSecondary" textAlign="center" mb={4}>
            At QSP we get a few questions around our products and services. Here’s a few quick FAQs
            in case you have some questions of your own.
          </Typography>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What kind of printing do you do?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We screen and digitally print onsite.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                How long does an order take to process?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                Our standard turn-around is 5 working days from approval.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What are your payment terms?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We have both a cash sale, or 30-day account available.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                How do we send you artwork?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We have a high mb server which can accept files up to 50mb. If your file is larger
                than that, simply email us a dropbox link.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What file types do you accept?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We prefer vector artwork supplied as a PDF or EPS with all fonts supplied or
                converted to outlines / paths. Please ensure all images are embedded and all PMS
                colour are listed in the file.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What colours do you print?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We can print CMYK, PMS or colour match to your sample.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What if I don't have any artwork?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We have a graphics department onsite to help with your artwork requirements.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                Can I have my stickers cut to shape?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                Yes, we can supply your stickers square-cut, kiss-cut, cut-to-shape or with a peel
                tab.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                Do you have a minimum order?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                No we don’t but for some products the more you order, the cheaper they get. We try
                not to waste anything so we will advise you if there is a better quantity to suit
                the number up on the sheet.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={9}>
            <AccordionSummary
              expandIcon={<IconChevronDown />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <Typography variant="h6" px={2} py={1}>
                What materials do you print on?
              </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                fontSize={16}
                pt={1}
                px={2}
                color="textSecondary"
              >
                We print on a large range of flat sheet materials like corflute, foam pvc,
                polypropylene, vinyls like standard white, clear to specialist vinyls like tamper
                proof and anti-graffiti and metal sheets like colorbond and ACM.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Questions;
