import React, { useEffect } from 'react';
import {
  CardContent,
  Typography,
  Grid,
  Card,
  CardMedia,
  Box,
  Stack,
  Skeleton,
  Button,
  Divider,
} from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';

import img1 from 'src/assets/images/blog/image-16.png';
import img2 from 'src/assets/images/blog/image-14.png';
import img3 from 'src/assets/images/blog/image-17.png';
import img4 from 'src/assets/images/blog/image-15.png';


const blogCard = [
  {
    title: 'Marketing agencies',
    content01:
      'From the super complex to the ridiculously fast, we know what your clients demand of you and how agencies work. We work with you to get the job done, on time, right the first time. We will be right there, from the first discussions about ideas, right through to the handover, providing advice and expertise, making you look good.',
    content02:
      'We work with you to get the job done, on time, right the first time. We will be right there, from the first discussions about ideas, right through to the handover, providing advice and expertise, making you look good.',
    img: img1,
    href: '/Products#marketing-agencies',
  },
  {
    title: 'Real estate',
    content01:
      'If you need signs that sell your brand as much as they sell houses, you need QSP. We hold your templates on file, so you can just order fast and get them delivered quickly, no matter how many you need. Stickers, coreflutes, flags, flip frames and banners, all perfectly colour matched to your brand, so you can be sure you get the best result every time.',
    content02: '',
    img: img2,
    href: '/Products#real-estate',
  },
  {
    title: 'Builders/ Construction industry',
    content01:
      'People driving past your construction site want to know what’s going on and who to call if they like what they see and site graphics give your organisation a professional look. That’s where QSP’s onsite signage is perfect. From eye-catching brand signage and mesh that marks your territory with high value visual real estate, right through to mandatory safety placards, we have your total site signage needs covered.',
    content02: '',
    img: img3,
    href: '/Products#builders-construction',
  },
  {
    title: 'Retail groups',
    content01:
      'Retail sites can be complex, with a myriad of visual applications, print materials and fixtures. When you are managing a national rollout across multiple stores, and they all have to go up overnight so it doesn’t disturb your customers, the process can seem insurmountable. QSP has years of experience in national retail space print production and fit-outs and can help you plan your application with military precision.',
    content02: '',
    img: img4,
    href: '/Products#retail-groups',
  },
];

const BlogCards = () => {
  const [isLoading, setLoading] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container spacing={3} mt={12} mb={12}>
      {blogCard.map((card, index) => (
        <Grid item xs={12} sm={12} key={index}>
          <AnimationFadeIn>
            <Card
              id="blogCards"
              sx={{ display: 'flex', p: 0, justifyContent: 'space-between', boxShadow: 'none' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }} id="blogCardContent">
                  <Typography
                    variant="h1"
                    color="#1CEBC2"
                    fontSize={40}
                    component={'div'}
                    lineHeight={1.2}
                    fontWeight={900}
                    mt={4}
                    mb={2}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    component="div"
                    fontWeight={200}
                    mt={3}
                    lineHeight={1.8}
                  >
                    {card.content01}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    component="div"
                    fontWeight={200}
                    mt={3}
                    lineHeight={1.8}
                  >
                    {card.content02}
                  </Typography>
                  <Button
                    className="button"
                    sx={{
                      mt: 6,
                    }}
                    variant="outlined"
                    color="secondary"
                    href={card.href}
                    fontWeight="500 !important"
                  >
                    View Products
                  </Button>
                </CardContent>
                <Stack direction="row" spacing={2} px={2} pb={3}></Stack>
              </Box>
              {isLoading ? (
                <Skeleton variant="square" animation="wave" width="100%" height={180}></Skeleton>
              ) : (
                <CardMedia
                  id="imgBlogCards"
                  component="img"
                  sx={{ width: '30%', height: '100%', minHeight: '500px', objectFit: 'cover' }}
                  image={card.img}
                  alt="Image"
                />
              )}
            </Card>
            <Divider sx={{ mt: 4, mb: 10 }} />
          </AnimationFadeIn>
        </Grid>
      ))}
    </Grid>
  );
};

export default BlogCards;
