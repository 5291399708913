import mock from 'src/_mockApis/mock.js';
//import mock from '../../../_mockApis/mock';
//import { sub } from 'date-fns';
//import { Chance } from 'chance';
import s1 from 'src/assets/images/products/Frame6.webp';
import s2 from 'src/assets/images/products/Frame5.webp';
import s3 from 'src/assets/images/products/Frame4.webp';
import s4 from 'src/assets/images/products/Frame2.webp';
import s5 from 'src/assets/images/products/Frame3.webp';
import s6 from 'src/assets/images/products/Frame9.webp';
import s7 from 'src/assets/images/products/Frame8.webp';
import s8 from 'src/assets/images/products/Frame7.webp';
import s9 from 'src/assets/images/products/Frame10.webp';
import s10 from 'src/assets/images/products/Frame11.webp';
import s31 from 'src/assets/images/products/Frame31.webp';
import s30 from 'src/assets/images/products/Frame30.webp';
import s26 from 'src/assets/images/products/Frame26.webp';
import s24 from 'src/assets/images/products/Frame24.webp';
import s16 from 'src/assets/images/products/Frame26.webp';
import s33 from 'src/assets/images/products/Frame33.webp';
import s22 from 'src/assets/images/products/Frame22.webp';
import s23 from 'src/assets/images/products/Frame23.webp';
import s34 from 'src/assets/images/products/Frame34.webp';
import s20 from 'src/assets/images/products/Frame20.webp';
import s35 from 'src/assets/images/products/Frame35.webp';
import s32 from 'src/assets/images/products/Frame32.webp';
import s21 from 'src/assets/images/products/Frame21.webp';
import s15 from 'src/assets/images/products/Frame15.webp';
import s17 from 'src/assets/images/products/Frame17.webp';
import s18 from 'src/assets/images/products/Frame18.webp';
import s19 from 'src/assets/images/products/Frame19.webp';
import s29 from 'src/assets/images/products/Frame29.webp';
import s14 from 'src/assets/images/products/Frame14.webp';
import s12 from 'src/assets/images/products/Frame12.webp';
import s36 from 'src/assets/images/products/Frame36.webp';
import s37 from 'src/assets/images/products/Frame37.webp';
import s25 from 'src/assets/images/products/Frame25.webp';
import s13 from 'src/assets/images/products/Frame13.webp';
import s38 from 'src/assets/images/products/Frame38.webp';
import s11a from 'src/assets/images/products/Frame11a.webp';
import s16a from 'src/assets/images/products/Frame16.webp';


//const chance = new Chance();

const ProductsData = [
  {
    id: 1,
    title: 'Real Estate Signage',
    related: false,
    category: ['Real Estate'],
    stock: true,
    qty: 1,
    photo: s1,
    additionalPhotos: [s1, s31, s26, s22, s24, s16, s33],
    url: 'Real-estate-signage',
    introduction:
      'QSP specialise in Real Estate signs and accessories for franchise groups and independent offices, large and small, Australia wide.',
    description:
      'We are proudly the preferred supplier of Real Estate signage to many of the national Real Estate franchise groups. We provide a range of products to aid the sales process from a corflute for sale sign to personalised flip frames to sold stickers. With our onsite graphics department we can design your products from scratch, to your requirements or within your corporate ID. We print CMYK, PMS or custom colours matched to your samples. \n Making you look good for over 40 years, why go anywhere else?',
    description2: ['<strong></strong><br>'],
    application: [
      'For sale / lease / auction signage made on corflute, ACM or Colorbond',
      'For sale / lease / auction window signage for external or internal application ',
      'Sold and under contract overlays printed on vinyl or corflute',
      'Single sided or double sided signs',
      'Directional signage, single or double sided signs',
      'Agent a/h overlays printed on vinyl or corflute',
      'Personalised or generic flip frames',
      'Colorbond a-frames',
      'Open home or auction flags',
      'Vinyl banners supplied with ropes and eyelets',
      'External office signage',
      'Pull up banners',
      'Promotion signage and stickers like wheelie bin stickers',
      'Internal wall graphics',
      'Custom signage',
    ],
    specifications: [
      '<strong>Corflute sign thickness</strong><br> 3.0mm up to 10mm',
      '<strong>ACM sign thickness</strong><br>3.0mm',
      '<strong>Colorbond signs</strong><br>0.55mm ',
      '<strong>Printing</strong><br>Digital or screenprinting facilities inhouse',
      '<strong>Colours</strong><br>PMS, CMYK or colour match to your sample',
      '<strong>Design</strong><br>Designed to your requirements or within corporate guidelines',
      '<strong>Sizing</strong><br>Standard size signs up to custom sizes available ',
      '<strong>Extras</strong><br>Signs can be supplied with square or round corners. Corflute signs can be supplied with eyelets or holes. Custom shapes, creasing, folding facilities inhouse ',
    ],
  },
  {
    id: 2,
    title: 'Corflute Signage',
    related: true,
    category: ['Marketing Agencies', ' | ', 'Real Estate', ' | ', 'Retail Groups'],
    stock: true,
    qty: 1,
    photo: s2,
    additionalPhotos: [s23, s2, s34, s23, s2, s34],
    url: 'Corflute-signage',
    introduction:
      'Corflute is a great cost-effective material for temporary external signage and internal signage giving you more bang for your buck!',
    description: [
      'Being lightweight, durable and weather resistance means corflute is utilised by almost every industry for advertising signs, racking signs, POS, safety signs, builders’ signs, real estate and much more. \n Queensland Screenprints proudly use Australian Made corflute and we pride ourselves on producing a high-quality corflute sign at a VERY competitive price. Our corflute signs are made onsite from our Brisbane-based factory and we deliver Australia-wide.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Real estate signs',
      'Builders site and lot signs, both generic and individually numbered',
      'Safety signs',
      'Point of sale signs including header signs, standee signage, stackable cubes, bollards and more',
      'Advertising signage, both internal and external application',
      'Prototype signage',
      'Wall calendars',
      'Yellow safety men',
      'Toblerones',
      'Racking/ load signs',
      'Fence signs',
    ],
    specifications: [
      '<strong>Origin</strong><br>Australian made corflute',
      '<strong>Printing</strong><br>PMS, CMYK or colour match to your sample. Digital or screenprinting facilities inhouse ',
      '<strong>Design</strong><br>Designed to your requirements or within corporate guidelines',
      '<strong>Thicknesses</strong><br>From 3.0mm – 10mm',
      '<strong>Colours</strong><br>White, black, green, blue, yellow, red & clear',
      '<strong>Sizes</strong><br>We print all the standard sheet sizes from small 600 x 900mm to extra large 3000 x 1500mm',
      '<strong>Extras</strong><br>square or round corners supplied with or without eyelets or holes (custom shapes, creasing, folding facilities inhouse) ',
    ],
  },
  {
    id: 3,
    title: 'Builders Signage',
    related: false,
    category: ['Builders', ' | ', 'Construction Industry'],
    stock: true,
    qty: 1,
    photo: s3,
    additionalPhotos: [s3, s20, s24, s21, s32, s34, s35],
    url: 'Builders-signage',
    introduction:
      'People driving past your construction site want to know what’s going on and who to call if they like what they see and site graphics give your organisation a professional look.',
    description: [
      'That’s where QSP’s onsite signage is perfect. From eye-catching brand signage and mesh that marks your territory with high value visual real estate, right through to mandatory safety placards, we have your total site signage needs covered.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Lot boards - numbered or generic',
      'Site information signs',
      'Safety signs',
      'Entry requirement signs',
      'Directional signs',
      'Yellow men (signalling signs)',
      'Temporary fence signs',
      'Builders information stickers',
      'Safety stickers',
      'Hard hat stickers',
      'Date tags',
      'Banners with ropes and eyelets',
      'Display home signage',
      'Site office internal and external signage',
      'Flags',
      'Fence mesh',
      'Billboards',
      'Industrial coreflute for floor protection, layers pads etc',
    ],
    specifications: [
      '<strong>Weather</strong><br>Signs are UV and weather resistant',
      '<strong>Sizes</strong><br>Come in a range of sizes or supplied in multiple sheets to make oversized signs',
      '<strong>Materials</strong><br>Supplied in a range of materials',
      '<strong>Coreflute</strong><br>Australian made and UV treated for outside applications - 3.0mm - 10mm thick and in a range of colours',
      '<strong>ACM</strong><br>3.0mm and in a range of colours/ finishes',
      '<strong>Colorbond</strong><br>0.55mm thick',
      '<strong>Polypropylene</strong><br>Range of thicknesses and colours',
      '<strong>PVC</strong><br> Range of thicknesses',
      '<strong>Foam PVC</strong><br> Range of thicknesses',
      '<strong>Vinyl</strong><br>Range of quality, colour and finish',
      '<strong>Printing</strong><br>Screenprinted, digital print, vinyl cut lettering, signs can be clear coated or overlaminated',
      '<strong>Extras</strong><br>Square or round corners, eyelets, holes, single or double sided print, signs can be supplied with timber stakes, grass spikes',
    ],
  },
  {
    id: 4,
    title: 'Stickers',
    related: true,
    category: ['Stickers', ' | ', 'Retail Groups'],
    stock: true,
    qty: 1,
    photo: s4,
    additionalPhotos: [s4, s29, s32, s19, s18, s17, s15, s26],
    url: 'Stickers',
    introduction:
      'Slap a sticker on it! Vinyl stickers are a great way to advertise your brand and provide information.',
    description: [
      'We produce stickers on a range of materials with different grades to suit the application you require. Our digital inks are high quality meaning the sticker is suitable for indoor and short-term outdoor applications. If you want extra life, that’s no problem… We can screenprint your sticker or supply them with UV overlaminate in gloss or matt. Need anti-tamper or anti-graffiti? You name it we can source it. Need floor graphics with a slip rating? No problem. Want stickers custom cut to shape, back slip, peel tabs or app tape. All of that and more is available to our clients. What next level? Ask us about an epoxy /dome finish to your vinyl stickers or chrome mylar.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Car, boat, truck branding',
      'Fish measuring stickers',
      'Safety stickers',
      'Instructional stickers',
      'Bumper stickers',
      'Call to action stickers',
      'Real estate stickers',
      'Wheelie bin stickers',
      'Product labels',
      'Warning labels',
      'Sampling labels',
      'Point of sale wall and window graphics',
      'Sales stickers',
      'Window stickers',
      'Floor graphics with a range of slip ratings',
    ],
    specifications: [
      '<strong>Colours</strong><br>Clear, white, black and a range or colours',
      '<strong>Applications</strong><br>Internal and External',
      '<strong>Adhesive</strong><br>Permanent, high tac, low tac, ultra low tac',
      '<strong>Vinyl grades</strong><br>1, 2, 3-5, 5-7 grades',
      '<strong>Film</strong><br>Silver and gold chrome mylar',
      '<strong>Printing</strong><br>Digtal and screenprint, overlaminate for a range of "life" and finishes',
      '<strong>Shapes</strong><br>Kiss cut and cut to shape',
    ],
  },
  {
    id: 5,
    title: 'External Signage',
    related: true,
    category: [
      'Builders & Construction Industry',
      ' | ',
      'Marketing Agencies',
      ' | ',
      'Real Estate',
      ' | ',
      'Retail Groups',
    ],
    stock: true,
    qty: 1,
    photo: s5,
    additionalPhotos: [s5, s5, s5, s5, s5, s5],
    url: 'External-signage',
    introduction:
      'Stand out from the crowd. External signage is a great way to catch the eye of potential customers, display goods and services or show new promotions.',
    description: [
      'External signage can be made from a range of materials to suit the application and objective. Think big impact ACM signage to window graphics or acrylic lightboxes. All our signage will be made to suit the conditions with a range of over laminate for extended life and finishes.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Shop front signs',
      'Fascia signs',
      'Light box prints',
      'Window signage',
      'Acrylic signs with and without backlighting',
    ],
    specifications: [
      '<strong>Materials</strong><br>ACM, Colorbond, coreflute (for short term signage), opal acrylic, coloured acrylic, range of vinyls, range of overlaminates dfor life and matt/ gloss finish, anti-graffiti',
      '<strong>Printing</strong><br>Digital or screenprinted',
      '<strong>Shapes</strong><br>Vinyl cut lettering, custom shapes',
      '<strong>Installation</strong><br>Installation can be Australia wide',
    ],
  },
  {
    id: 6,
    title: 'ACM Signage',
    related: true,
    category: [
      'Builders & Construction Industry',
      ' | ',
      'Marketing Agencies',
      ' | ',
      'Real Estate',
      ' | ',
      'Retail Groups',
    ],
    stock: true,
    qty: 1,
    photo: s6,
    additionalPhotos: [s6, s36, s6, s36, s6, s36],
    url: 'ACM-signage',
    introduction:
      'If you are looking for a durable sign which can be cut, v-scored and folded, ACM is the product for you.',
    description: [
      'ACM (Aluminium composite material) is great for external corporate signage, real estate signs, builder’s signs and any sign where you would like extended life. We print direct to our ACM and can supply with or without overlaminate for extended life. Whether it’s a full colour lifestyle print or a safety sign we can supply the right product for you.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Fascia signage',
      'Real estate signs',
      'Builders signs',
      'Safety signs',
      'Internal wall pelmets',
      'Cut to shape custom signage',
    ],
    specifications: [
      '<strong>Thicknesses</strong><br>3.0mm and 4.0mm',
      '<strong>Colours & sizes</strong><br>Range of colours and sheet sizes',
      '<strong>Finishes</strong><br>Custom finishes available upon request including fire retard ACM. Range of overlaminates for life and matt / gloss finish, anti-graffiti. Can be v-grooved for folding.  ',
      '<strong>Grade</strong><br>Our standard ACM is signage grade only and not to be used as cladding',
    ],
  },
  {
    id: 7,
    title: 'Point of Sale Solutions',
    related: false,
    category: [
      'Builders & Construction Industry',
      ' | ',
      'Marketing Agencies',
      ' | ',
      'Real Estate',
      ' | ',
      'Retail Groups',
    ],
    stock: true,
    qty: 1,
    photo: s7,
    additionalPhotos: [s7, s15, s14, s22, s25, s29, s12, s31, s37],
    url: 'Point-of-sale-solutions',
    introduction:
      'When you need your product to pop, QSP has a solution. From entire campaign signage to one off standee signs, we make you look good.',
    description: [
      'With POS you are only limited by your imaging as QSP has all the tool available to make your ideas come to life. From printing on timber panels to shelf wobblers, standee signs and stackable cubes we can supply the signage that best suits your needs. We have a range of internal and external point of sale solutions which we can distribute Australia wide so contact us to find out more.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Stand here Floor graphics – range of slip rating available',
      'Lifestyle Wall graphics',
      'Internal and external campaign decals',
      'Welcome signage',
      'Bollard',
      'Standee signs',
      'Stackable cubes',
      'Wobblers / shelf talkers',
      'Hanging posters',
      'Give away stickers',
      'Bag labels',
    ],
    specifications: [
      '<strong>Printing</strong><br>Digital and screenprint. Single and double sided',
      '<strong>Materials</strong><br>Range of materials',
      '<strong>Finshes</strong><br>Cut, crease, fold, eyelet, route. Gloss clear and matt clear over print available. Multi-layer digital print with white and clear layers to give a textured effect',
      '<strong>Extras</strong><br>Adhesive backing available for some products. Multi-site distribution available Australia wide. Sample to full production',
    ],
  },
  {
    id: 8,
    title: 'Custom Signs',
    category: [
      'Builders & Construction Industry',
      ' | ',
      'Marketing Agencies',
      ' | ',
      'Real Estate',
      ' | ',
      'Retail Groups',
    ],
    stock: true,
    qty: 1,
    photo: s8,
    additionalPhotos: [s8, s30, s13, s11a, s9, s38],
    url: 'Custom-signs',
    introduction:
      'Need some signage inspo? Not sure what material you need or what product you want?',
    description: [
      'At QSP we have a range of materials available to find a solution just for you. If we don’t stock it on the floor, through our wealth of suppliers, we can source it for you. As they say, there is no harm in asking. You tell us your ideas and we’ll source the right product for you.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Wedding stationary printed on acrylic, polypropylene or card ',
      'Party signs printed on corflute, armaboard or card based stock ',
      'Gift boxes print on artboard or similar stocks',
      'Printed Safety cones made from polypropylene ',
      'Yearly wall planners printed on corflute, armaboard or foam PVC ',
    ],
    specifications: [
      '<strong>Contact us for specifications of the you would like to enquire.</strong><br>',
      '<strong></strong><br>',
      '<strong></strong><br>',
      '<strong></strong><br>',
      '<strong></strong><br>',
      '<strong></strong><br>',
      '<strong></strong><br>',
    ],
  },
  {
    id: 9,
    title: 'Colorbond Signs',
    category: ['Marketing Agencies', ' | ', 'Real Estate', ' | ', 'Retail Groups'],
    stock: true,
    qty: 1,
    photo: s6,
    additionalPhotos: [s6, s6, s6, s6, s6, s6],
    url: 'Colorbond-signs',
    introduction:
      'Colorbond signage is commonly used where a greater life than corflute is required.',
    description: [
      'Colorbond signs are more robust than corflute or plastic signs and are widely used in the building and manufacturing industries for site safety signs, entry signs and fence signs. All our colorbond signs are proudly manufactured in house at our Brisbane based factory and we can supply these signs with holes and round corners.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Safety signs',
      'Site rules signs',
      'Site rules signs',
      'Car park signage',
      'Sporting field signage',
      'Property signboards',
      'Permanent property signs',
      'Fence signs',
    ],
    specifications: [
      '<strong>Size</strong><br>0.55mm sign white standard',
      '<strong>Colours</strong><br>Colours available upon request',
      '<strong>Printing</strong><br>Screenprinted or digital printed',
      '<strong>Sizes</strong><br>Guillotined to size',
      '<strong>Extras</strong><br>Holed or cornered',
    ],
  },
  {
    id: 10,
    title: 'Foam PVC',
    category: ['Marketing Agencies', ' | ', 'Real Estate', ' | ', 'Retail Groups'],
    stock: true,
    qty: 1,
    photo: s9,
    additionalPhotos: [s6, s9, s10, s6, s9, s10],
    url: 'Foam-pvc',
    introduction: 'Foam PVC is a great choice for internal signage.',
    description: [
      'The smooth surface of foam PVC means our prints look amazing. Foam PVC is lightweight making it perfect for point of sale signage, internal hanging signs, wall signage but is also commonly used for internal safety signage like load and racking signs. PVC can be cut to shape easily and supplied with holes.',
    ],
    description2: ['<strong></strong><br>'],
    application: [
      'Wall signage like pelmets to full wall graphics',
      'Hanging signs',
      'Cut-out standee signs',
      'Header cards',
      'Aisle signs',
      'A-frame inserts',
      'POS installations',
      'Racking signs',
      'Load signs',
      'Safety signs',
      'Numbered/ alphabetical signs',
    ],
    specifications: [
      '<strong>Sizes</strong><br>Large sheet size',
      '<strong>Thicknesses</strong><br>From 1mm to 20mm',
      '<strong>Printing</strong><br>Screen and digital print quality Single and double sided',
      '<strong>Shape</strong><br>Standard square cut to custom shapes',
      '<strong>Extras</strong><br>Holes',
    ],
  },
  {
    id: 11,
    title: 'Other Substrates',
    category: ['Marketing Agencies', ' | ', 'Real Estate', ' | ', 'Retail Groups'],
    stock: true,
    qty: 1,
    photo: s16a,
    additionalPhotos: [s16a, s16a, s16a, s16a, s16a, s16a],
    url: 'Other-substrates',
    introduction:
      'Through our sister company BFS Plastics and being part of the QNP Group we have access to a range of other products, processes and materials. ',
    description: [''],
    description2: [
      '<strong>Polycarbs / Lexans</strong> – Lexan and polycarbonates are most commonly used for touch pads, fascias and measuring stickers. They are also widely used as safety labels in the motor industry. We can screen or digitally print this material and are mostly reverse printed to protect the print from scratches, weather etc. For extra pop, we can epoxy your lexan labels and supply with adhesive backing to suit your application.<br></br><strong>Polypropylene</strong> – used for safety signs, instructional tags, point of sale signage and more.<br></br><strong>Artboard</strong>– great for gift boxes as this material can be cut and creased easily.<br></br><strong>Epoxy / Domed</strong>– great for making your vinyl labels pop on things like ute trays, boats and carpet samples.<br></br><strong>Aluminium, stainless steel and brass signage</strong>– for those hard-wearing products like chain tags, modification plates, fire tags and in-ground marker plates.<br></br><strong>Screen anodised printing</strong>– for scratch-resistant print on aluminium for products like bar code tags on your vending machines.<br></br><strong>Etched and paint-filled</strong>– great for plaques and way-finder signage.<br></br><strong>Reflective vinyl decals and signs</strong>– great for plaques and way-finder signage.<br></br><strong>Screen anodised printing</strong>– exit hear glow in the dark vinyl stickers to reflective numbers.<br></br><strong>Bar codes, sequential numbering</strong>– when you need to keep track of your products.<br></br><strong>Wayfinding signage</strong>– for decorative way-finder signage which lasts',
    ],
    application: [
      'Contact us for specifications of the you would like to enquire.',
    ],
    specifications: [
      '<strong>Contact us for specifications of the you would like to enquire.</strong><br>',
    ],
  },
];

mock.onGet('/api/data/eCommerce/ProductsData').reply(() => {
  return [200, ProductsData];
});

export default ProductsData;
