import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

// components
const qspIconArrow = require('../../../assets/images/aboutus/icon-arrow-left-sm.png');

const CapabilitiesServicesGrid = () => {
  return (
    <Box id="section-about-capabilities-icons">
      <Grid container spacing={0} mt={4}>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Wide format
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Digital
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Custom Solutions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Large format
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Screen Printing
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={4}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Design Services
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CapabilitiesServicesGrid;
