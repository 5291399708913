import React from 'react';
import { Grid, Link, Typography, Container, Stack, Box } from '@mui/material';
import BusinessCard01 from './BusinessCard01';
import FooterCard01 from './FooterCard01';
import FooterCard02 from './FooterCard02';
import QuoteBannerCard from '../../pages/contactUs/QuoteBannerCard';
//import logoIcon from 'src/assets/images/logos/logoIcon.svg';

const ContactFooter = () => {
  return (
    <Box
      sx={{ background: 'linear-gradient(45deg, #1C2945, #051233)' }}
      borderRadius={0}
      pt={2}
      pb={11}
    >
      <Container maxWidth="xl">
        <QuoteBannerCard />
      </Container>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="top"
          mt={15}
          spacing={2}
          sx={{
            flexDirection: { sm: 'column', md: 'row', xs: 'column' },
          }}
        >
          <BusinessCard01 />
          <FooterCard01 />
          <FooterCard02 />
        </Stack>
        <Grid container spacing={3} justifyContent="center" mt={4}>
          <Grid item xs={12} sm={12} lg={12} textAlign="center">
            {/* <img src={logoIcon} alt="icon" /> */}
            <Typography fontSize="12" color="#5A6A85" mt={1} mb={0}>
              © Copyright 2024 QSP. All rights reserved |
              <Link
                className="footer-bottom-links"
                href="/Privacy-Policy"
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  className="footer-bottom-links"
                  color="#7C8FAC"
                  component="span"
                  display="inline"
                >
                  {' '}
                  Privacy Policy
                </Typography>{' '}
              </Link>{' '}
              |
              <Link href="/Terms-and-conditions" sx={{ textDecoration: 'none' }}>
                <Typography
                  className="footer-bottom-links"
                  color="#7C8FAC"
                  component="span"
                  display="inline"
                >
                  {' '}
                  Terms & Conditions
                </Typography>{' '}
              </Link>{' '}
              |
              <Link href="/Learn/Artwork-requirements" sx={{ textDecoration: 'none' }}>
                <Typography
                  className="footer-bottom-links"
                  color="#7C8FAC"
                  component="span"
                  display="inline"
                >
                  {' '}
                  Artwork Requirements
                </Typography>{' '}
              </Link>{' '}
              |
            </Typography>
            <Typography color="#5A6A85" mt={1} mb={-10} sx={{ fontSize: '11px' }}>
              Designed & Developed by
              <Link
                target="_blank"
                href="https://designsdusted.com/"
                sx={{ textDecoration: 'none' }}
              >
                <Typography
                  className="footer-bottom-links"
                  color="#7C8FAC"
                  component="span"
                  display="inline"
                  sx={{ fontSize: '12px' }}
                >
                  {' '}
                  Designs Dusted
                </Typography>{' '}
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactFooter;
