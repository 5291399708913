import { Box, Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react';
import thankyouAnimation from 'src/assets/lottie/ThankYou.json';

const ThankYou = () => (
  <Box
    display="flex"
    flexDirection="column"
    height="100vh"
    textAlign="center"
    justifyContent="center"
  >
    <Container maxWidth="md">
      <Lottie
        id="thankyou-animation"
        animationData={thankyouAnimation}
        loop={true}
        style={{ width: '75%', display: 'inline-block' }}
      />
      <Typography align="center" variant="h5" fontWeight={200} mt={-16} mb={4}>
        Thank you for your enquiry. We will get back to you within 3 business days.
      </Typography>
      <Button color="primary" variant="contained" component={Link} to="/" disableElevation  style={{ marginRight:'1rem'}}>
        Return to Home
      </Button>
      <Button color="primary" variant="contained" component={Link} to="/Products" disableElevation>
        Return to Products
      </Button>
    </Container>
  </Box>
);

export default ThankYou;
