import React, { useState } from 'react';
import { Box, Button, Grid, styled, Paper } from '@mui/material';
import { IconChevronDown } from '@tabler/icons';
import { NavLink } from 'react-router-dom';

const Navigations = () => {
  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: '16px',
    color: theme.palette.text.light,
    borderBottom: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#1C2945',
      color: 'aliceblue',
    },
  }));

  // Learn
  const [open2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  // Products
  const [open3, setOpen3] = useState(false);

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  return (
    <>
      <NavLink to="/" className="active-link">
        <StyledButton color="inherit" variant="text">
          Home
        </StyledButton>
      </NavLink>
      <NavLink to="/About-us" className="active-link">
        <StyledButton color="inherit" variant="text">
          About Us
        </StyledButton>
      </NavLink>
      {/* Products Dropdown */}
      <Box>
        <StyledButton
          color="inherit"
          variant="text"
          onMouseEnter={handleOpen3}
          onMouseLeave={handleClose3}
          sx={{
            color: open3 ? 'primary.main' : (theme) => theme.palette.text.light,
          }}
          endIcon={<IconChevronDown size="15" style={{ marginLeft: '-5px', marginTop: '2px' }} />}
        >
          Products
        </StyledButton>
        {open3 && (
          <Paper
            onMouseEnter={handleOpen3}
            onMouseLeave={handleClose3}
            sx={{
              position: 'absolute',
              right: '400px',
              top: '52px',
              width: '316px',
              margin: '0 auto',
              backgroundColor: '#e6e7ebcf',
              backdropFilter: 'blur(5px)',
            }}
            elevation={9}
          >
            <Grid container id="product-dropdown-container">
              <Grid item sm={1} id="product-dropdown-item">
                <Box p={1}>
                  <NavLink to="/Products" className="active-link product-dropdown-link">
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      All Products
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Real-estate-signage"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Real Estate Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Corflute-signage"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Corflute Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Builders-signage"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Builders Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink to="/Products/Stickers" className="active-link product-dropdown-link">
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Stickers
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/External-signage"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      External Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink to="/Products/ACM-signage" className="active-link product-dropdown-link">
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      ACM Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Point-of-sale-solutions"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Point-of-Sale Solutions
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Custom-signs"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Custom Signs
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Colorbond-signs"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Colorbond Signage
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Foam-pvc"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Foam PVC
                    </StyledButton>
                  </NavLink>
                  <NavLink
                    to="/Products/Other-substrates"
                    className="active-link product-dropdown-link"
                  >
                    <StyledButton
                      className="product-dropdown-button"
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Other Substrates
                    </StyledButton>
                  </NavLink>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
      {/* <NavLink to="/Products" className="active-link">
        <StyledButton color="inherit" variant="text">
          Products
        </StyledButton>
      </NavLink> */}
      <NavLink to="/How-we-help" className="active-link">
        <StyledButton color="inherit" variant="text">
          How We Help
        </StyledButton>
      </NavLink>
      <NavLink to="/Capabilities" className="active-link">
        <StyledButton color="inherit" variant="text">
          Capabilities
        </StyledButton>
      </NavLink>
      <NavLink to="/Our-works" className="active-link">
        <StyledButton color="inherit" variant="text">
          Our Works
        </StyledButton>
      </NavLink>
      <Box>
        <StyledButton
          color="inherit"
          variant="text"
          onMouseEnter={handleOpen2}
          onMouseLeave={handleClose2}
          sx={{
            color: open2 ? 'primary.main' : (theme) => theme.palette.text.light,
          }}
          endIcon={<IconChevronDown size="15" style={{ marginLeft: '-5px', marginTop: '2px' }} />}
        >
          Learn
        </StyledButton>
        {open2 && (
          <Paper
            onMouseEnter={handleOpen2}
            onMouseLeave={handleClose2}
            sx={{
              position: 'absolute',
              right: '30px',
              top: '52px',
              width: '180px',
              margin: '0 auto',
              backgroundColor: '#e6e7ebcf',
              backdropFilter: 'blur(5px)',
            }}
            elevation={9}
          >
            <Grid container>
              <Grid item sm={1}>
                <Box p={1}>
                  {/* <NavLink to="/Learn/News" className="active-link">
                    <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      News
                    </StyledButton>
                  </NavLink> */}
                  <NavLink to="/Learn/Frequently-asked-questions" className="active-link">
                    <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Faqs
                    </StyledButton>
                  </NavLink>
                  <NavLink to="/Learn/Artwork-requirements" className="active-link">
                    <StyledButton
                      color="inherit"
                      variant="text"
                      sx={{
                        color: '#2A3547',
                        textAlign: 'left',
                        transition: 'all 0.3s',
                        '&:hover': {
                          bgcolor: '#E5E5E5',
                          color: '#FF007A',
                        },
                      }}
                    >
                      Artwork Requirements
                    </StyledButton>
                  </NavLink>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Box>
      <NavLink to="/Contact-us" className="active-link">
        <StyledButton color="inherit" variant="text">
          Contact Us
        </StyledButton>
      </NavLink>
    </>
  );
};

export default Navigations;
