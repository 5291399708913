import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import AnimationFadeIn from '../animation/Animation';

const BenefitsTitle = () => {

    return (
      <Grid container spacing={3} justifyContent="left">
        <Grid item xs={12} sm={10} lg={8}>
          <AnimationFadeIn>
            <Typography
              variant="h2"
              fontWeight={700}
              textAlign="left"
              color={'#1CEBC2'}
              sx={{
                fontSize: {
                  lg: '36px',
                  xs: '25px',
                },
                lineHeight: {
                  lg: '43px',
                  xs: '30px',
                },
              }}
            >
              Quality products, amazing service.
            </Typography>
          </AnimationFadeIn>
          <Box pt={2} pb={3}>
            <AnimationFadeIn>
              <Typography variant="h5" fontWeight={300}>
                Our biggest aim is to make the ordering (and reordering) process seamless and easy
                for our clients. That’s why we’ve put together our online store.
              </Typography>
            </AnimationFadeIn>
          </Box>
        </Grid>
      </Grid>
    );
};

export default BenefitsTitle;
