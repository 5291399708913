import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import EmblaCarousel from './EmblaCarousel';
import Footer from 'src/components/landingpage/footer/Footer';

const slides = [0, 1, 2, 3, 4, 5, 6];

const OurWorks2 = () => {
  return (
    <PageContainer
      title="QSP Queensland Screenprints – Real Estate Signs, Builders Signs, Point of Sale Signs, Australia"
      description="QSP is your one stop printing shop; from Banners and Builders Signs to Real Estate Signs, we've got you covered!"
    >
      <div
        style={{
          height: '0px',
          width: '100%',
          background: 'linear-gradient(90deg, #1C2945, #051233)',
          marginTop: '0rem',
          marginBottom: '-4rem',
        }}
      />
      <EmblaCarousel slides={slides} options={{ loop: false }} />
      <div
        style={{
          height: '100px',
          width: '100%',
          background: 'linear-gradient(90deg, #1C2945, #051233)',
          marginTop: '-2rem',
          marginBottom: '-4rem',
        }}
      />
      <Footer />
    </PageContainer>
  );
};

export default OurWorks2;
