import React from 'react';
import { styled, Button, Typography, Stack, Box } from '@mui/material';
import ChildCard from 'src/components/shared/ChildCard';
import { NavLink } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.text.light,
  '&:hover': { 
    backgroundColor: '#1C2945',
    color: 'aliceblue',
  
  },
}));

const FooterCard01 = () => (
  <ChildCard>
    <Box className="footer-quicklinks-col">
      <Typography fontWeight={600} color="#5A6A85" variant="h4" mb={2}>
        Quick Links
      </Typography>
      <Stack
        direction="row"
        gap={2}
        alignItems="start"
        mb={3}
        sx={{ marginLeft: { xl: '0px', md: '0px', sm: '-4px', xs: '-8px' } }}
      >
        <Stack direction="column" gap={2} alignItems="start" mb={3}>
          <NavLink to="/About-us" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              About Us
            </StyledButton>
          </NavLink>
          <NavLink to="/Products" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              Products
            </StyledButton>
          </NavLink>
          <NavLink to="/How-we-help" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              How We Help
            </StyledButton>
          </NavLink>
          <NavLink to="/Capabilities" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              Capabilities
            </StyledButton>
          </NavLink>
        </Stack>
        <Stack direction="column" gap={2} alignItems="start" mb={3}>
          <NavLink to="/Learn/Frequently-asked-questions" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              FAQs
            </StyledButton>
          </NavLink>
          <NavLink to="/Get-quote" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              Get Quote
            </StyledButton>
          </NavLink>
          <NavLink to="/Contact-us" className="active-link">
            <StyledButton
              className="footer-quicklinks"
              color="inherit"
              variant="text"
              sx={{
                transition: 'all 0.25s',
                '&:hover': {
                  color: '#1CEAC1',
                  transition: 'all 0.25s',
                },
              }}
            >
              Contact Us
            </StyledButton>
          </NavLink>
        </Stack>
      </Stack>
    </Box>
  </ChildCard>
);

export default FooterCard01;
