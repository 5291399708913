import React from 'react';
import {
  AppBar,
  styled,
  Toolbar,
  Container,
  Box,
  Button,
  Stack,
  Tooltip,
  useMediaQuery,
  IconButton,
  Drawer,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Logo from 'src/layouts/full/shared/logo/Logo';
import Navigations from './Navigations';
import MobileSidebar from './MobileSidebar';
import {
  IconMenu2,
  IconPhone,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
} from '@tabler/icons';
import { NavLink } from 'react-router-dom';

const LpHeader = () => {
  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    justifyContent: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: '80px',
    },
    background: 'linear-gradient(45deg, #1C2945, #051233)',
    //backgroundColor: theme.palette.backgroundNavy.navy,
    borderBottom: `1px solid #e5eaef24`,
    //borderBottom: `1px solid ${theme.palette.divider}`,
  }));

  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    color: theme.palette.text.secondary,
  }));

  //   sidebar
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [y, setY] = React.useState(window.scrollY);

  const handleNavigation = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollY);
    },
    [y],
  );

  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <AppBarStyled position="fixed" elevation={y ? 8 : 0}>
      <Container
        maxWidth="xl"
        sx={{
          backgroundColor: 'inherit',
          height: '32px',
          marginBottom: '8px',
          [`@media (max-width: 720px)`]: {
            marginBottom: '16px',
            height: 'auto',
          },
        }}
      >
        <ToolbarStyled
          className="toptoolbar"
          sx={{
            [`@media (max-width: 720px)`]: {
              display: 'none',
              marginBottom: '0px',
            },
          }}
        >
          <Box flexGrow={1} />
          <Tooltip title="linkedin">
            <a
              href="https://au.linkedin.com/company/queenslandscreenprints"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="linkedin"
              >
                <IconBrandLinkedin width={20} />
              </IconButton>
            </a>
          </Tooltip>
          <Tooltip title="facebook">
            <a
              href="https://www.facebook.com/profile.php?id=100054205793176"
              target="_blank"
              rel="noreferrer"
            >
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="facebook"
              >
                <IconBrandFacebook width={20} />
              </IconButton>
            </a>
          </Tooltip>
          <Tooltip title="instagram">
            <a href="https://www.instagram.com/qspgroup/" target="_blank" rel="noreferrer">
              <IconButton
                sx={{
                  transition: 'all 0.3s',
                  '&:hover': {
                    transform: 'scale(1.1)',
                  },
                }}
                variant="contained"
                color="primaryLight"
                aria-label="instagram"
              >
                <IconBrandInstagram width={20} />
              </IconButton>
            </a>
          </Tooltip>
          <Box width="8px" />
          <Button
            className="phone-button"
            sx={{
              transition: 'all 0.3s',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
            variant="outlined"
            color="primary"
            href="tel:+61732773566"
            fontWeight="500 !important"
            startIcon={<IconPhone width={18} />}
          >
            (07) 3277 3566
          </Button>
          <Box width="8px" />
          <NavLink to="/Get-quote" className="active-link">
            <Button
              sx={{
                transition: 'all 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
              color="primary"
              variant="contained"
            >
              Get Quote
            </Button>
          </NavLink>
        </ToolbarStyled>
      </Container>
      <Container maxWidth="xl">
        <ToolbarStyled>
          <Logo />
          <Box flexGrow={1} />
          {lgDown ? (
            <IconButton
              color="primaryLight"
              aria-label="menu"
              onClick={handleDrawerOpen}
              id="mobile-menu-button"
            >
              <IconMenu2 size="24" />
            </IconButton>
          ) : null}
          {lgUp ? (
            <Stack spacing={1} direction="row" alignItems="center">
              <Navigations />
            </Stack>
          ) : null}
        </ToolbarStyled>
      </Container>
      <Drawer
        anchor="right"
        open={open}
        variant="temporary"
        onClose={toggleDrawer(false)}
        transitionDuration={{ enter: 320, exit: 320 }}
        PaperProps={{
          sx: {
            width: '98%',
            marginRight: '-2rem',
            border: '0 !important',
            backgroundColor: 'transparent',
            backdropFilter: 'blur(5px)',
            overflow: 'hidden',
            transition: 'all 0.3s !important', // Ensures smooth transition for all properties
            boxShadow: (theme) => theme.shadows[9],
            '@media (max-width: 1194px)': {
              width: '94%',
            },
            // Adding a transform for smooth sliding effect
            transform: open ? 'translateX(0)' : 'translateX(100%)', // Slides in from right
          },
        }}
      >
        <CloseIcon
          onClick={toggleDrawer(false)}
          sx={{ position: 'absolute', margin: '2rem', left: '-1.9rem', color: 'white' }}
        />
        <Typography
          onClick={toggleDrawer(false)}
          variant="h6"
          sx={{
            position: 'absolute',
            textAlign: 'center',
            color: 'white',
            marginTop: '4.5rem',
            marginLeft: '-10px',
            opacity: '0.7',
            rotate: '-90deg',
            fontWeight: '500',
          }}
        >
          Close
        </Typography>
        <Container
          sx={{
            background: '#ffffffeb',
            width: '92%',
            height: '100%',
            position: 'absolute',
            transformOrigin: 'top left',
            right: '0',
            '@media (max-width: 1194px)': {
              width: '97%',
            },
            '@media (max-width: 760px)': {
              width: '96%',
            },
            '@media (max-width: 560px)': {
              width: '95%',
            },
            '@media (max-width: 460px)': {
              width: '94%',
            },
            '@media (max-width: 360px)': {
              width: '92%',
            },
          }}
        >
          <MobileSidebar />
        </Container>
      </Drawer>
    </AppBarStyled>
  );
};

export default LpHeader;
