import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import Footer from '../../landingpage/footer/Footer';
import Breadcrumb from '../../../layouts/full/shared/breadcrumb/Breadcrumb';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/Terms-an-conditions',
    title: 'Terms and Conditions',
  },
];

const TermsAndConditions = () => {
  return (
    <PageContainer
      title="Terms and Conditions - QSP Queensland Screenprints"
      description="Job Approval: Job is approved once the proof is signed off on. We will not print anything until you have approved your proof for print. It is the customers responsibility to check all details on the proof are correct as per order. Changes made after approval can incur extra charges. Payment Cash sale: Once approved […]"
    >
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[8, 12]} />
        <Breadcrumb items={BCrumb} />
        <Grid container spacing={3} mt={[-8, -4]}>
          <Grid item xs={12} lg={12}>
            <AnimationFadeIn>
              <Typography
                variant="h4"
                color="#051233"
                fontSize={40}
                lineHeight={1.2}
                fontWeight={900}
                mt={4}
              >
                Terms and Conditions
              </Typography>
            </AnimationFadeIn>
            <Box pt={4} pb={3} pr={4} ml={2}>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={700} mt={4} lineHeight={1.8}>
                  Job Approval:
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  <ul>
                    <li>
                      Job is approved once the proof is signed off on. We will not print anything
                      until you have approved your proof for print. It is the customers
                      responsibility to check all details on the proof are correct as per order.
                      Changes made after approval can incur extra charges.
                    </li>
                  </ul>
                </Typography>
                <Typography variant="h5" fontWeight={700} mt={4} lineHeight={1.8}>
                  Payment
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  <ul>
                    <li>
                      Cash sale: Once approved you will be sent an invoice for payment. `Your job
                      will proceed to production once payment has been received.
                    </li>
                    <li>
                      Account: Once approved your job will go straight into production for
                      processing and despatched as soon as ready or as stipulated on your PO.
                      Payment is required within your account payment terms.
                    </li>
                  </ul>
                </Typography>
              </AnimationFadeIn>
            </Box>
            <Box mb={8} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </PageContainer>
  );
};

export default TermsAndConditions;
