import React from 'react';
import { Stack, Typography, Tooltip, IconButton, Box } from '@mui/material';

import ChildCard from 'src/components/shared/ChildCard';
import {
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
} from '@tabler/icons';

const FooterCard02 = () => (
  <ChildCard>
  <Box className="footer-socials-col">
    <Typography fontWeight={600} color="#5A6A85" variant="h4" mb={2}>
      Follow Us
    </Typography>
    <Stack direction="row" gap={2} alignItems="center" mb={3}>
      <Tooltip title="linkedin">
        <a href="https://au.linkedin.com/company/queenslandscreenprints" target="_blank" rel="noreferrer">
        <IconButton
          sx={{
            transition: 'all 0.3s',
            '&:hover': {
              color: '#1CEAC1',
              transform: 'scale(1.1)',
            },
          }}
          variant="contained"
          color="primaryLight"
          aria-label="linkedin"
        >
          <IconBrandLinkedin width={24} />
        </IconButton>
        </a>
      </Tooltip>
      <Tooltip title="facebook">
        <a href="https://www.facebook.com/profile.php?id=100054205793176" target="_blank" rel="noreferrer">
        <IconButton
          sx={{
            transition: 'all 0.3s',
            '&:hover': {
              color: '#1CEAC1',
              transform: 'scale(1.1)',
            },
          }}
          variant="contained"
          color="primaryLight"
          aria-label="facebook"
        >
          <IconBrandFacebook width={24} />
        </IconButton>
        </a>
      </Tooltip>
      <Tooltip title="instagram">
        <a href="https://www.instagram.com/qspgroup/" target="_blank" rel="noreferrer">
        <IconButton
          sx={{
            transition: 'all 0.3s',
            '&:hover': {
              color: '#1CEAC1',
              transform: 'scale(1.1)',
            },
          }}
          variant="contained"
          color="primaryLight"
          aria-label="instagram"
        >
          <IconBrandInstagram width={24} />
        </IconButton>
        </a>
      </Tooltip>
    </Stack>
    </Box>
  </ChildCard>
);

export default FooterCard02;
