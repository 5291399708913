import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
// components
//import LpHeader from '../../../components/landingpage/header/Header';
import Footer from '../../landingpage/footer/Footer';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/Learn/Artwork-requirements',
    title: 'Learn',
  },
  {
    title: 'Artwork Requirements',
  },
];


const ArtworkRequirements = () => {
  return (
    <PageContainer
      title="Artwork Requirements - QSP Queensland Screenprints"
      description="For supplied artwork, we kindly require one of the following VECTOR GRAPHIC formats we can edit: Our team uses Adobe Illustrator Creative Cloud Preferred file types are: .ai, .eps, and .pdf Please ensure that all images are embedded within the file and that the text has been converted to outlines (alternatively please supply font files […]"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[-8, -4]}>
          <Grid item xs={12} lg={12}>
            <AnimationFadeIn>
              <Typography
                variant="h4"
                color={'primary.green'}
                fontSize={40}
                lineHeight={1.2}
                fontWeight={900}
                mt={4}
              >
                Artwork Requirements
              </Typography>
            </AnimationFadeIn>
            <Box pt={4} pb={3} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  For supplied artwork, we kindly require one of the following{''}{' '}
                  <b>VECTOR GRAPHIC</b> {''}
                  formats we can edit:
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  Our team uses Adobe Illustrator Creative Cloud
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={3} lineHeight={1.8}>
                  <b>Preferred file types are:</b> .ai, .eps, and .pdf
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  Please ensure that all images are embedded within the file and that the text has
                  been converted to outlines (alternatively please supply font files along with the
                  artwork files).
                </Typography>

                <Typography variant="h5" fontWeight={700} mt={4} lineHeight={1.8}>
                  Use of Images
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  Please ensure all images are <b>embedded</b> within your artwork before emailing
                  us your files. If you are unable to, please include your images as separate files
                  in your email (.jpg, .psd, .tif, .jpeg, .bmp). High resolution images provide
                  higher quality artwork.
                </Typography>

                <Typography variant="h5" fontWeight={700} mt={4} lineHeight={1.8}>
                  Use of Large or Multiple files
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  If you are supplying large and/or multiple files that are more than 100MB, please
                  consider uploading your files to DropBox and emailing us the link to access these
                  files.
                </Typography>

                <Typography variant="h5" fontWeight={700} mt={4} lineHeight={1.8}>
                  A Note about Fonts
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  Due to rapid development and many variations in font design technology, fonts
                  purchased from different suppliers are not fully interchangeable, even if they
                  share the same name.
                </Typography>
                <Typography variant="h6" fontWeight={200} mt={1} lineHeight={1.8}>
                  We would kindly appreciate if you provide all fonts associated with your order,
                  or, if your artwork does not contain a large amount of text, please{' '}
                  <b> convert all text to outlines </b> (paths/curves). Of course, retaining fill,
                  line and colour specifications etc. where necessary.
                </Typography>
              </AnimationFadeIn>
            </Box>
            <Box mb={8} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </PageContainer>
  );
};

export default ArtworkRequirements;
console.clear();
