const LightThemeColors = [
  {
    name: 'BLUE_THEME',
    palette: {
      primary: {
        main: '#FF007A',
        light: '#FFF',
        dark: '#FF4DA2',
        navy: '#051233',
        grey: '#5A6A85',
        purple: '#756EFF',
        darkpurple: '#453de4',
        green: '#1CEBC2',
        darkgreen: '#02b3a9',
        contrastText: '#ffffff',
      },
      primaryPink: {
        main: '#FF007A',
        light: '#FFF',
        dark: '#FF4DA2',
        contrastText: '#ffffff',
      },
      primaryLight: {
        main: '#FFF',
        light: '#ffffff',
        dark: '#FF4DA2',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#051233',
        light: '#E8F7FF',
        dark: '#051233',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: 'AQUA_THEME',
    palette: {
      primary: {
        main: '#0074BA',
        light: '#EFF9FF',
        dark: '#006DAF',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#47D7BC',
        light: '#EDFBF7',
        dark: '#39C7AD',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: 'PURPLE_THEME',
    palette: {
      primary: {
        main: '#756EFF',
        light: '#F2ECF9',
        dark: '#6E35B7',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#95CFD5',
        light: '#EDF8FA',
        dark: '#8BC8CE',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: 'GREEN_THEME',
    palette: {
      primary: {
        main: '#0A7EA4',
        light: '#F4F9FB',
        dark: '#06769A',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#CCDA4E',
        light: '#FAFBEF',
        dark: '#C3D046',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: 'CYAN_THEME',
    palette: {
      primary: {
        main: '#01C0C8',
        light: '#EBF9FA',
        dark: '#00B9C0',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#FB9678',
        light: '#FFF5F2',
        dark: '#F48B6C',
        contrastText: '#ffffff',
      },
    },
  },
  {
    name: 'ORANGE_THEME',
    palette: {
      primary: {
        main: '#FA896B',
        light: '#FBF2EF',
        dark: '#F48162',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#0074BA',
        light: '#EFF9FF',
        dark: '#006FB1',
        contrastText: '#ffffff',
      },
    },
  },
];

export { LightThemeColors };
