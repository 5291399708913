import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';


const StillQuestions = () => {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} lg={10}>
        <Box bgcolor="primary.light" p={5} mt={7}>
          <Typography variant="h3" textAlign="center" mt={3} mb={1} color={'primary.green'}>
            Still have questions
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            lineHeight="23px"
            color="textSecondary"
            textAlign="center"
          >
            Can't find the answer your're looking for ? Please chat to our friendly team.
          </Typography>
          <Box textAlign="center" mt={3}>
            <NavLink to="/Contact-us" className="active-link">
              <Button variant="contained" color="primary">
                Chat with us
              </Button>
            </NavLink>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StillQuestions;
