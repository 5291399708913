import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchProducts } from 'src/store/apps/eCommerce/EcommerceSlice';
import LpHeader from './components/landingpage/header/Header';
import { ThemeSettings } from './theme/Theme';

import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';

/* Initialize GA4 */
   import ReactGA from 'react-ga4';
   ReactGA.initialize('G-P670RG3JRN');



function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    const initializeLocomotiveScroll = async () => {
      if (typeof window !== 'undefined') {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;

        const locomotiveScrollInstance = new LocomotiveScroll({
          el: document.querySelector('.lenis.lenis-smooth'),
          smooth: true,
        });

        document.querySelector('.lenis.lenis-smooth').style.scrollBehavior = 'auto';
        document.querySelector('.lenis.lenis-smooth').style.height = 'auto';

        return () => {
          locomotiveScrollInstance.destroy();
          clearInterval();
        };
      }
    };
    initializeLocomotiveScroll();
  }, []);

  /* Track pageviews GA4 */
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  /* Track events GA4 */
  /* const handleButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked a button',
    });
  }; */
  
  console.clear();

  return (
    <ThemeProvider theme={theme}>
      <LpHeader />
      <CssBaseline />
      <ScrollToTop>{routing}</ScrollToTop>
    </ThemeProvider>
  );
}

export default App;