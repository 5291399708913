import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
// components
import Footer from '../../landingpage/footer/Footer';
import BenefitsNoSlider from 'src/components/landingpage/benefits/BenefitsNoSlider';
import HowWeHelpServicesGrid from './HowWeHelpServicesGrid';
import BlogCards from './BlogCards';


const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/How-we-help',
    title: 'How we help',
  },
];


const HowWeHelp = () => {
  return (
    <PageContainer
      title="How we help – QSP Queensland Screenprints"
      description="No matter the industry, we have a solution. We do not pretend to be “all things to all people” or the “best at everything”. Our goal is to develop long term, mutually beneficial partnerships with companies that require our core product and service offerings. We realise that every business is different and therefore require unique […]"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[-4, -8]}>
          <Grid item xs={12} lg={12}>
            <AnimationFadeIn>
              <Typography
                variant="h1"
                color="#756EFF"
                fontSize={56}
                lineHeight={1.2}
                fontWeight={900}
                mt={2}
              >
                No matter the industry,<br></br> we have a solution.
              </Typography>
            </AnimationFadeIn>
            <Box pt={4} pb={0} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h4" fontWeight={700} lineHeight={1.8}>
                  We do not pretend to be “all things to all people” or the “best at everything”.
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={1} lineHeight={1.8}>
                  Our goal is to develop long term, mutually beneficial partnerships with companies
                  that require our core product and service offerings. We realise that every
                  business is different and therefore require unique needs when it comes to
                  printing. Our Business Development Managers and Concept Consultants work closely
                  with our clients to understand the dynamics of their business and develop the
                  right print solution to suit their needs.
                </Typography>
              </AnimationFadeIn>
            </Box>
          </Grid>
        </Grid>
        <AnimationFadeIn>
          <HowWeHelpServicesGrid />
        </AnimationFadeIn>
        <BlogCards />
      </Container>
      <BenefitsNoSlider />
      <Footer />
    </PageContainer>
  );
};

export default HowWeHelp;
console.clear();
