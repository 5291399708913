import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import { Grid, Box, Container, Typography } from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
// components
//import LpHeader from '../../../components/landingpage/header/Header';
import Footer from '../../landingpage/footer/Footer';
import BenefitsNoSlider from 'src/components/landingpage/benefits/BenefitsNoSlider';
import ServicesGrid from './ServicesGrid';
import qspImage from '../../../assets/images/aboutus/qsp-4-square.png';
import qspImage2 from '../../../assets/images/aboutus/Our-History-Placeholder.png';

//const qspImage = require('../../../assets/images/aboutus/qsp-4-square.png');
//const qspImage2 = require('../../../assets/images/aboutus/Our-History-Placeholder.png');

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/About-us',
    title: 'About us',
  },
];

const AboutUs = () => {
  return (
    <PageContainer
      title="About us – QSP Queensland Screenprints"
      description="Where we started Queensland Screenprints (QSP) was established in 1965 to supply the ever increasing need for screenprint products. Today, we have expanded our range of services to cater for the growing demand of the industry to provide you, our valued clients, the convenience of a one-stop-shop for all types of screen & digital print, […]"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Container maxWidth="xl">
        <Grid container spacing={3} mt={[-8, -4]}>
          <Grid item xs={12} lg={6}>
            <AnimationFadeIn>
              <Typography
                variant="h1"
                color="#1CEBC2"
                fontSize={40}
                lineHeight={1.2}
                fontWeight={900}
                mt={0}
              >
                Where we started
              </Typography>
            </AnimationFadeIn>
            <Box pt={2} pb={3} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} lineHeight={1.8}>
                  Queensland Screenprints (QSP) was established in 1965 to supply the ever
                  increasing need for screenprint products. Today, we have expanded our range of
                  services to cater for the growing demand of the industry to provide you, our
                  valued clients, the convenience of a one-stop-shop for all types of screen &
                  digital print, brand management, signage, and marketing needs.
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={3} lineHeight={1.8}>
                  As part of the QNP Group (est. 1947), with over 70 years of experience, we are
                  proud to be servicing some of Australia’s most successful companies with their
                  small to large format printing and signage requirements.
                </Typography>
              </AnimationFadeIn>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <AnimationFadeIn>
              <img
                id="aboutus-image-1"
                src={qspImage}
                alt="About Us"
                style={{
                  borderRadius: '16px',
                  width: '100%',
                  maxHeight: '500px',
                  objectFit: 'cover',
                  objectPosition: '0 -32px',
                  border: '2px solid #f0f0f0',
                  boxShadow: '0 0 20px 0 rgba(0,0,0,0.1)',
                }}
              />
            </AnimationFadeIn>
          </Grid>
          <Grid item xs={12} lg={6} mb={4} mt={4}></Grid>
        </Grid>
        <Grid container spacing={3} mt={2} id="section-how-we-help">
          <Grid item xs={12} lg={6}>
            <AnimationFadeIn>
              <img
                id="aboutus-image-2"
                src={qspImage2}
                alt="About Us"
                style={{
                  borderRadius: '16px',
                  width: '100%',
                  maxHeight: '500px',
                  objectFit: 'cover',
                  border: '2px solid #f0f0f0',
                  boxShadow: '0 0 20px 0 rgba(0,0,0,0.1)',
                }}
              />
            </AnimationFadeIn>
          </Grid>
          <Grid item xs={12} lg={6}>
            <AnimationFadeIn>
              <Typography
                variant="h1"
                color="#1CEBC2"
                fontSize={40}
                lineHeight={1.2}
                fontWeight={900}
                mt={0}
              >
                How we help
              </Typography>
            </AnimationFadeIn>
            <Box pt={2} pb={3} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} lineHeight={1.8}>
                  Our client base ranges from Real Estate, the building industry, Commercial &
                  Retail clients to Manufacturing and Government departments. This includes First
                  National Real Estate, Choices Flooring, TFH Hire, Bombardier Trains, Arnott’s
                  Biscuits, Cox Mowers, Soak Creative – just to name a few.
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={3} lineHeight={1.8}>
                  QSP specialises in the volume production of small to large format digital
                  printing, screen printing, cut-to-shape, vinyl and epoxy stickers, and much more.
                </Typography>
              </AnimationFadeIn>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} lg={12}>
            <Box pt={4} pb={3} pr={4}>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} lineHeight={1.8}>
                  We do not pretend to be “all things to all people” or the “best at everything”.
                  Our goal is to develop long term, mutually beneficial partnerships with companies
                  that require our core product and service offerings.
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={3} lineHeight={1.8}>
                  We realise that every business is different and therefore require unique needs
                  when it comes to printing. Our Business Development Managers and Concept
                  Consultants work closely with our clients to understand the dynamics of their
                  business and develop the right print solution to suit their needs.
                </Typography>
              </AnimationFadeIn>
              <AnimationFadeIn>
                <Typography variant="h5" fontWeight={200} mt={3} lineHeight={1.8}>
                  Our comprehensive service range, which is constantly expanding through our
                  research and development initiatives, includes:
                </Typography>
              </AnimationFadeIn>
            </Box>
          </Grid>
        </Grid>
        <AnimationFadeIn>
          <ServicesGrid />
        </AnimationFadeIn>
      </Container>
      <BenefitsNoSlider />
      <Footer />
    </PageContainer>
  );
};

export default AboutUs;
console.clear();
