import React, { useEffect } from 'react';
import {
  CardContent,
  Typography,
  Grid,
  Card,
  CardMedia,
  Box,
  Stack,
  Skeleton,
} from '@mui/material';
import AnimationFadeIn from 'src/components/landingpage/animation/Animation';

import img1 from 'src/assets/images/capabilities/VUTEk-GS2000-UV.png';
import img2 from 'src/assets/images/capabilities/Mimaki-JFX200-2513EX.png';
import img3 from 'src/assets/images/capabilities/Mimaki-JFX200-2513.png';
import img4 from 'src/assets/images/capabilities/UCJV300-160.png';
import img5 from 'src/assets/images/capabilities/Mimaki-JV400SUV.png';
import img6 from 'src/assets/images/capabilities/Screenprint-Lines-with-UV.png';
import img7 from 'src/assets/images/capabilities/elitron_kombo_sdc_fronte.png';
import img8 from 'src/assets/images/capabilities/CF22-1225-Mimaki-Table-Cutter.png';


const capabilitiesCard = [
  {
    title: '1 x VUTEk® GS2000 UV',
    content01:
      'This two-meter wide flatbed/roll-fed UV hybrid printer has increased our current printing capacity with a larger range of direct to substrate printing. Our Vutek runs eight-colour plus white or Fast-4/5 for photo-realistic quality or production speeds with dual resolution capability of 600 dpi or 1000 dpi for superior graphics that will set you ahead of your competition.',
    img: img1,
  },
  {
    title: '1 x Mimaki JFX200-2513EX',
    content01:
      'Printing up to 2500mm x 1300mm cmyk + white + clear + primer the print capabilities are endless…We can print on corflute, acrylic, acm, wood, cardboard and even metal. The multi-layer function means we can print 2.5D making your prints look and feel amazing! Think water droplets or that woodgrain effect or just a simple clear coat to make your print POP. Let your imagination run wild and we’ll print it for you.',
    img: img2,
  },
  {
    title: '1 x Mimaki JFX200-2513',
    content01:
      'Offers the ability to produce a diverse range of prints for both indoor and outdoor use. It is ideally suited for applications such as backlit displays and signage, signs and posters, interior décor, glass and metal decorative panels and more.',
    img: img3,
  },
  {
    title: '1 x UCJV300-160',
    content01:
      'The UCJV300 is a versatile inkjet printer having a combined function of both printing and cutting mounted with UV-curable ink. Printing 6 colour + white this is a high spec machine equipped with the 4-layer/5-layer print function in addition to UV LED print and Print&Cut. What you get is a high quality printer capable of printing the simple 1 colour jobs to high quality point of purchase signage.',
    img: img4,
  },
  {
    title: '3 x Mimaki JV400SUV',
    content01:
      'The UCJV300 is a versatile inkjet printer having a combined function of both printing and cutting mounted with UV-curable ink. Printing 6 colour + white this is a high spec machine equipped with the 4-layer/5-layer print function in addition to UV LED print and Print&Cut. What you get is a high quality printer capable of printing the simple 1 colour jobs to high quality point of purchase signage.',
    img: img5,
  },
  {
    title: '2 x Screenprint Lines with UV',
    content01:
      'With not 1 but 2 in-house screen printing facilities, QSP fuses the traditional and modern day print technology to offer the best quality finish for their customers. Having our own printing facility allows us to maintain the very best quality, service and turn-around time at low cost.',
    img: img6,
  },
  {
    title: '2 x Elitron SDC+',
    content01:
      'Extremely flexible, this conveyor cutting system, comes with a working area of 2100x3200mm and multi tool functionality. The machines can kiss cut vinyl, cut flat sheet to shape, crease and score with ease or route and v-cut acm. The cutting potential means we not only can print your product but finish it all in house saving time and money. Working from a roll the automated system works through a projector/camera to set and forget. Load your profiles and you’ll come back to a whole roll of product all cut to shape.',
    img: img7,
  },
  {
    title: '1 x CF22-1225 Mimaki Table Cutter',
    content01:
      'Fixed blade, kiss cut, V-cut & creasing. Cutting a range of prints straight off our flatbeds allows for fast cutting turn around times.',
    img: img8,
  },
];

const CapabilitiesCards = () => {
  const [isLoading, setLoading] = React.useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container spacing={3} mt={4} mb={12}>
      {capabilitiesCard.map((card, index) => (
        <Grid item xs={12} sm={12} lg={6} key={index}>
          <AnimationFadeIn>
            <Card
              id="capabilitiesCard"
              sx={{ display: 'flex', p: 0, justifyContent: 'space-between', boxShadow: 'none' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {isLoading ? (
                  <Skeleton variant="square" animation="wave" width="50%" height={180}></Skeleton>
                ) : (
                  <Box p={2} sx={{ display: 'flex', flexDirection: 'column', width:'100%', alignItems:'center' }}>
                    <CardMedia
                      id="imgCapabilitiesCard"
                      component="img"
                      sx={{ width: '100%', height: 'auto', objectFit: 'contain', maxHeight: '250px' }}
                      image={card.img}
                      alt="Image"
                    />
                  </Box>
                )}

                <CardContent sx={{ flex: '1 0 auto' }} id="capabilitiesCardContent">
                  <Typography
                    variant="h2"
                    color="grey.400"
                    component={'div'}
                    lineHeight={1.2}
                    fontWeight={900}
                    mt={4}
                    mb={2}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    component="div"
                    fontWeight={200}
                    mt={3}
                    lineHeight={1.8}
                  >
                    {card.content01}
                  </Typography>
                  <Typography
                    variant="h5"
                    color="text.secondary"
                    component="div"
                    fontWeight={200}
                    mt={3}
                    mb={8}
                    lineHeight={1.8}
                  >
                    {card.content02}
                  </Typography>
                </CardContent>
                <Stack direction="row" spacing={2} px={2} pb={3}></Stack>
              </Box>
            </Card>
          </AnimationFadeIn>
        </Grid>
      ))}
    </Grid>
  );
};

export default CapabilitiesCards;
