import React from 'react';
import { Grid, Box, Typography } from '@mui/material';


// components
const qspIconArrow = require('../../../assets/images/aboutus/icon-arrow-left-sm.png');

const ServicesGrid = () => {
  return (
    <Box id="section-about-capabilities-icons">
      <Grid container spacing={0} mt={-4}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Corporate Signage
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Brand Management
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Marketing Prints
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Banners/Pop-Up Banners
              </Typography>
            
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Corflute, ACM, Foam PVC Signs
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Signage Frames & Spikes
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Point-of-Sales Displays
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Lightbox Prints and Acrylic Signs
              </Typography>
            
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Internal and External Window Graphics
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Vinyl Stickers
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Real Estate Signs & Stickers
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Epoxy Labels
              </Typography>
            
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Builders Signs
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Floor Graphics
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Standee & Stackable Cube Signs
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            
              <Typography
                variant="h5"
                fontWeight={900}
                mt={3}
                lineHeight={1.3}
                sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
                Safety Signage
              </Typography>
            
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} mb={8} mt={2}></Grid>
      </Grid>
    </Box>
  );
};

export default ServicesGrid;
