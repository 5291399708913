import React from 'react';
import { Grid, Box, Typography } from '@mui/material';

// components
const qspIconArrow = require('../../../assets/images/aboutus/icon-arrow-left-sm.png');

const HowWeHelpServicesGrid = () => {
  return (
    <Box id="section-about-capabilities-icons">
      <Grid container spacing={0} mt={4}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Corporate Signage
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Brand Management
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              High quality products at great prices
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Digital & Screen printing facilities
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={0} mb={4}>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Large & Small Format printing
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              On-site Graphics department
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Nationwide delivery
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} lg={3}>
          <Box pt={3} pb={3} pr={4}>
            <Typography
              variant="h5"
              fontWeight={900}
              mt={3}
              lineHeight={1.3}
              sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
            >
              <img src={qspIconArrow} alt="About Us" style={{ height: '50px', width: 'auto' }} />
              Service with a smile
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWeHelpServicesGrid;
