import React from 'react';
import PageContainer from 'src/components/container/PageContainer';
import Breadcrumb from 'src/layouts/full/shared/breadcrumb/Breadcrumb';
import { Grid } from '@mui/material';
import Footer from 'src/components/landingpage/footer/Footer';

import Questions from './Questions';
import StillQuestions from './StillQuestions';

const BCrumb = [
  {
    to: '/',
    title: 'Home',
  },
  {
    to: '/Learn/Faq',
    title: 'Learn',
  },
  {
    title: 'Faq',
  },
];


const Faq = () => {
  return (
    <PageContainer
      title="Frequently Asked Questions – QSP Queensland Screenprints"
      description="At QSP we get a few questions around our products and services. Here’s a few quick FAQs in case you have some questions of your own. What kind of printing do you do? We screen and digitally print onsite. How long does an order take to process? Our standard turn-around is 5 working days from […]"
    >
      <Grid container spacing={3} mt={[8, 12]} />
      <Breadcrumb items={BCrumb} />
      <Grid container spacing={3} p={2}>
        <Grid item xs={12}>
          <Questions />
          <StillQuestions />
        </Grid>
      </Grid>
      <Footer />
    </PageContainer>
  );
};

export default Faq;
console.clear();