import React from 'react';
import { Typography, Box, Button, styled, CardContent, Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import BlankCard from '../../shared/BlankCard';

import badgeImg from 'src/assets/images/landingpage/shape/badge.svg';
import lineImg from 'src/assets/images/landingpage/shape/line-bg-2.svg';

const ImgCard = styled(BlankCard)(() => ({
  backgroundImage: `url(${lineImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  marginTop: '-56px',
}));

const StyledButton = styled(Button)(() => ({
  padding: '13px 34px',
  fontSize: '18px',
}));

const ContactBannerCard = () => {
  return (
    <ImgCard className="bannerCardContact">
      <CardContent
        sx={{
          background: 'linear-gradient(45deg, #756EFF, #918BFF, #9F99FF, #756EFF)',
          boxShadow: '0 0 20px 0 rgb(0 0 0 / 15%), 0 4px 6px 0px rgb(0 0 0 / 8%))',
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row', xl: 'row' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <Box pl={2}>
              <img
                src={badgeImg}
                alt="bagde"
                className="banner-icon"
                style={{ filter: 'brightness(10) saturate(1)' }}
              />
            </Box>
            <Box pl={3}>
              <Typography variant="h2" color="primary.light" fontWeight={'bold'} sx={{ paddingRight:'8px'}}>
                Contact us today and we’ll make you look good.
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {/* subtext */}
              </Typography>
            </Box>
          </Stack>
          <Stack
            sx={{
              mt: {
                xs: 2,
                sm: 0,
              },
              mr: {
                lg: 2,
              },
              width: {
                xs: '100%',
                sm: 'auto',
              },
            }}
          >
            <NavLink to="/Contact-us" className="active-link">
              <StyledButton variant="contained" color="primary" >
              {/* <StyledButton variant="outlined" color="primary" sx={{ background: '#FFF' }}> */}
                Get in Touch
              </StyledButton>
            </NavLink>
          </Stack>
        </Stack>
      </CardContent>
    </ImgCard>
  );
};

export default ContactBannerCard;
